declare var window

const getAppVersion = () => {
  const version = window.__CI_APP_VERSION__.replace('-SNAPSHOT', '')
  return version !== '0.0.0' ? version : '1.145.0'
}

const getTranslationUrl = (locale: string) => {
  const version = getAppVersion()
  return `https://static.parastorage.com/services/editor-script/${version}/assets/locale/editor-script_${locale}.json`
}

export const getTranslateFunction = async (locale: string) => {
  const translationsJson = await (await fetch(getTranslationUrl(locale))).json()
  return (keyToTranslate: string): string => {
    return translationsJson[keyToTranslate]
  }
}
