import {
  BASE_INSTALL_CONFIG,
  EVENTS_APP_DEF_ID,
  EVENTS_PAGE,
  MEMBERS_APP_DEF_ID,
  MEMBERS_DEPENDANT_APPS,
  ProgressBarConfig,
  PAID_PLANS_APP_DEF_ID,
  PROFILE_PAGE,
  PROMO_INSTALL_CONFIG,
  PROMO_UPDATE_CONFIG,
} from './constants'
import {getTranslateFunction} from './services/translations'
import {waitALittle} from './services/wait-a-little'

let sdk: any
let appToken: string
let t: any
let origin: string

export const editorReady = async (editorSDK: any, token: string, data: any): Promise<any> => {
  sdk = editorSDK
  appToken = token
  const locale = await sdk.environment.getLocale()
  t = await getTranslateFunction(locale)
  origin = data?.origin?.type

  await setAppAPI()
  await setDependantApps()
}

const setAppAPI = async () => {
  const api: EventsApi = {
    installMembersArea: async () => await installMembersArea(true),
    installMembersAreaSections: async () => {
      const progressBarPromise = showProgressBar(PROMO_UPDATE_CONFIG)
      await installMembersAreaSections()
      progressBarPromise.then(async close => {
        await waitALittle(1500)
        close()
      })
    },
  }
  await sdk.editor.setAppAPI(appToken, api)
}
const isEventsInstalled = () =>
  sdk.tpa.isApplicationInstalled(appToken, {
    appDefinitionId: EVENTS_APP_DEF_ID,
  })

const showProgressBar = (config: ProgressBarConfig): Promise<Function> => {
  return new Promise(async resolve => {
    const panelRef = await sdk.editor.openProgressBar(appToken, {
      title: t(config.title),
      totalSteps: 3,
      currentStep: 1,
      stepTitle: t(config.step1),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: 2,
      stepTitle: t(config.step2),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: 3,
      stepTitle: t(config.step3),
    })
    await waitALittle(800)
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, false, {panelRef})
    })
  })
}

export const handleAction = async (args: any) => {
  const {type, payload} = args

  switch (type) {
    case 'appInstalled':
      switch (payload.appDefinitionId) {
        case EVENTS_APP_DEF_ID:
          if (origin === 'ADI') {
            return
          }
          await installMembersArea()
          return
        case MEMBERS_APP_DEF_ID:
          if (await isEventsInstalled()) {
            await installMembersAreaSections()
          }
          return
        default:
          return
      }
    case 'migrate':
      if (payload?.addPaidPlans) {
        return addPaidPlans()
      }
      break
    default:
      console.log(type, payload)
      return
  }
}

const withDefaultPageProps = (page: any) => {
  return {
    numbers: {},
    showInLoginMenu: false,
    showInMemberMenu: true,
    social: true,
    socialHome: false,
    urlOverride: null,
    ...page,
  }
}

const getMembersApi = () =>
  sdk.application.getPublicAPI(appToken, {
    appDefinitionId: MEMBERS_APP_DEF_ID,
  })

const setDependantApps = async () => {
  const membersAPI = await getMembersApi()
  const dependencies = Object.keys(MEMBERS_DEPENDANT_APPS).map(function(app) {
    return MEMBERS_DEPENDANT_APPS[app].appDefinitionId
  })
  return (
    membersAPI &&
    membersAPI.setDependantApps({
      appId: EVENTS_APP_DEF_ID,
      dependencies,
    })
  )
}

const installMembersArea = async (isInstallingFromMembersPromo = false) => {
  const progressBarPromise = isInstallingFromMembersPromo
    ? showProgressBar(PROMO_INSTALL_CONFIG)
    : showProgressBar(BASE_INSTALL_CONFIG)
  await sdk.application.install(appToken, {
    appDefinitionId: MEMBERS_APP_DEF_ID,
    initiatorAppDefinitionId: EVENTS_APP_DEF_ID,
  })

  // sdk.editor.openProgressBar does not always resolve
  progressBarPromise.then(async close => {
    await waitALittle(2000)
    close()
  })
}

const installMembersAreaSections = async () => {
  const membersAPI = await getMembersApi()
  // installing together events page with any of the others during editorReady breaks the installation
  await membersAPI.addApplications([
    withDefaultPageProps(PROFILE_PAGE),
    //withDefaultPageProps(NOTIFICATIONS_PAGE), // Wait for MA phase 2
    //withDefaultPageProps(SETTINGS_PANEL)
  ])
  await membersAPI.addApplications([withDefaultPageProps(EVENTS_PAGE)])
  await setDependantApps()
}

const addPaidPlans = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: PAID_PLANS_APP_DEF_ID})
}

export const getAppManifest = () => ({})
export const getControllerPresets = async () => []
